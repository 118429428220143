import "whatwg-fetch";

import type FetchConfig from "../models/FetchConfig.js";
import FetchExecutor from "./FetchExecutor.js";

/**
 * FetchExecutor when running on the client
 *
 * @author @jchaiken
 */
class ClientFetchExecutor extends FetchExecutor {
  protected makeRequestInit(config: FetchConfig): RequestInit {
    return {
      method: config.method,
      headers: this.getHeaders(config.headers),
      body: config.body ? JSON.stringify(config.body) : undefined,
      signal: config.signal,
      credentials: "include",
    };
  }

  protected getHost(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is exposed in root.tsx
    const windowObj = window as any;
    return windowObj.ENV.TURBINE_EXTERNAL_BASE_URL;
  }
}

export default ClientFetchExecutor;
